import { id } from 'date-fns/locale'
import $axios from './axios'
import { getCookie } from 'cookies-next'

export default {
  auth: {
    signIn: (params) => {
      return $axios.post('auth/sign_in', params, { headers: headers() })
    },
    signUp: (params) => {
      return $axios.post('auth', params, { headers: headers() })
    },
    signOut: () => {
      return $axios.delete('auth/sign_out', { headers: headers() })
    },
    validate: () => {
      return $axios.get('auth/validate_token', { headers: headers() })
    },
    resetPassword(params) {
      return $axios.post('auth/password', params, { headers: headers() })
    },
    acceptInvitation(params) {
      return $axios.patch('auth/invitation', params, { headers: headers() })
    },
    invite(params) {
      return $axios.post('auth/invitation', params, { headers: headers() })
    },
    oauthUrl: (params) => {
      return $axios.get('social_logins/oauth_url', { params, headers: headers() })
    },
    verifyCode: (params) => {
      return $axios.post('social_logins/verify_code', params, { headers: headers() })
    }
  },
  users: {
    index: (params) => {
      return $axios.get('users', { params, headers: headers() })
    },
    update:  (params) => {
      return $axios.put('users', params, { headers: headers() })
    }
  },
  subscription: {
    quota: () => {
      return $axios.get('subscriptions/quota', { headers: headers() })
    },
    paymentInfo: () => {
      return $axios.get('subscriptions/payment_info', { headers: headers() })
    },
    changePlan: (params) => {
      return $axios.put('subscriptions/change_plan', params, {
        headers: headers(),
      })
    },
    cancel: () => {
      return $axios.delete('subscriptions/cancel', { headers: headers() })
    },
    endTrial: () => {
      return $axios.put('subscriptions/end_trial', {}, { headers: headers() })
    }
  },
  profile: {
    update: (params) => {
      return $axios.put('auth', params, { headers: headers() })
    },
    updatePassword: (params) => {
      return $axios.put('auth/password', params, { headers: headers() })
    },
  },
  invoices: {
    index: (params = {}) => {
      return $axios.get('invoices', { params, headers: headers() })
    },
    show: (id) => {
      return $axios.get(`invoices/${id}`, { headers: headers() })
    },
  },
  integrations: {
    rephraseSubject: (params) => {
      return $axios.post(
        'integrations/rephrase_subject',
        params,
        { headers: headers() }
      )
    },
    verifyEmail: (params) => {
      return $axios.post('integrations/verify_email', params, {
        headers: headers(),
      })
    },
    chat: (params) => {
      return $axios.post('integrations/chat', params, { headers: headers() })
    }
  },
  dashboard: {
    getStarted: () => {
      return $axios.get('dashboard/get_started', { headers: headers() })
    },
    monthlyReport: () => {
      return $axios.get('dashboard/monthly_report', { headers: headers() })
    },
    monthlyMailEvents: () => {
      return $axios.get('dashboard/monthly_mail_events', { headers: headers() })
    },
  },
  service: {
    create: (params) => {
      return $axios.post('services', params, { headers: headers() })
    },
    update: (id, params) => {
      return $axios.put(`services/${id}`, params, { headers: headers() })
    },
    test: (id) => {
      return $axios.get(`services/${id}/test`, { headers: headers() })
    },
    index: () => {
      return $axios.get(`services`, { headers: headers() })
    },
    marketingMessages: () => {
      return $axios.get(`services/marketing_message`, { headers: headers() })
    },
    destroy: (id) => {
      return $axios.delete(`services/${id}`, { headers: headers() })
    }
  },
  dataSource: {
    create: (params) => {
      return $axios.post('data_sources', params, { headers: headers() })
    },
    index: (params={}) => {
      return $axios.get(`data_sources`, { params, headers: headers() })
    },
    update: (id, params) => {
      return $axios.put(`data_sources/${id}`, params, { headers: headers() })
    },
    destroy: (id) => {
      return $axios.delete(`data_sources/${id}`, { headers: headers() })
    },
    forms: (id) => {
      return $axios.get(`data_sources/${id}/forms`, { headers: headers() })
    },
    formFields: (id, params) => {
      return $axios.post(`data_sources/${id}/form_fields`, params, { headers: headers() })
    }
  },
  listDataSource: {
    create: (listId, params) => {
      return $axios.post(`lists/${listId}/list_data_sources`, params, { headers: headers() })
    },
    index: (listId) => {
      return $axios.get(`lists/${listId}/list_data_sources`, { headers: headers() })
    },
    update: (listId, id, params) => {
      return $axios.put(`lists/${listId}/list_data_sources/${id}`, params, { headers: headers() })
    },
    destroy: (listId, id) => {
      return $axios.delete(`lists/${listId}/list_data_sources/${id}`, { headers: headers() })
    },
    show: (listId, id) => {
      return $axios.get(`lists/${listId}/list_data_sources/${id}`, { headers: headers() })
    }
  },
  apiKeys: {
    index: () => {
      return $axios.get('api_keys', { headers: headers() })
    },
    revoke: (id) => {
      return $axios.delete(`api_keys/${id}`, { headers: headers() })
    },
    create: (params) => {
      return $axios.post('api_keys', params, { headers: headers() })
    }
  },
  emailServices: {
    index: (params) => {
      return $axios.get('email_service_lists', { headers: headers() })
    },
  },
  dataSourceList: {
    index: (params={}) => {
      return $axios.get('data_source_lists', { params, headers: headers() })
    },
  },
  verifiedSenders: {
    index: (params = {}) => {
      return $axios.get('verified_senders', { params, headers: headers() })
    },
    create: (params) => {
      return $axios.post('verified_senders', params, { headers: headers() })
    },
    show: (id) => {
      return $axios.get(`verified_senders/${id}`, { headers: headers() })
    },
    delete: (id) => {
      return $axios.delete(`verified_senders/${id}`, { headers: headers() })
    },
    verify: (id) => {
      return $axios.post(`verified_senders/${id}/verify`, {}, {
        headers: headers(),
      })
    },
    sync: (params={}) => {
      return $axios.get('verified_senders/sync', { params, headers: headers() })
    }

  },
  lists: {
    index: (params = {}) => {
      return $axios.get('lists', { params, headers: headers() })
    },
    all: (params = {}) => {
      return $axios.get('lists/all', { params, headers: headers() })
    },
    clear: (id) => {
      return $axios.post(`lists/${id}/clear`, {}, { headers: headers() })
    },
    create: (params) => {
      return $axios.post('lists', params, { headers: headers() })
    },
    show: (id) => {
      return $axios.get(`lists/${id}`, { headers: headers() })
    },
    update: (id, params) => {
      return $axios.put(`lists/${id}`, params, { headers: headers() })
    },
    delete: (id) => {
      return $axios.delete(`lists/${id}`, { headers: headers() })
    },
    export: (id, params = {}) => {
      return $axios.get(`lists/${id}/export`, { params, headers: headers() })
    }
  },
  contacts: {
    index: (params) => {
      return $axios.get(`lists/${params.listId}/contacts`, {
        params,
        headers: headers(),
      })
    },
    show: (listId, contactId, params = {}) => {
      return $axios.get(`lists/${listId}/contacts/${contactId}`, {
        params,
        headers: headers(),
      })
    },
    update: (listId, contactId, params = {}) => {
      return $axios.put(`lists/${listId}/contacts/${contactId}`, params, {
        headers: headers(),
      })
    },
    create: (listId, params = {}) => {
      return $axios.post(`lists/${listId}/contacts/`, params, {
        headers: headers(),
      })
    },
  },
  customFields: {
    index: (listId) => {
      return $axios.get(`lists/${listId}/custom_fields`, { headers: headers() })
    },
    show: (listId, fieldId, params = {}) => {
      return $axios.get(`lists/${listId}/custom_fields/${fieldId}`, {
        params,
        headers: headers(),
      })
    },
    create: (listId, params = {}) => {
      return $axios.post(`lists/${listId}/custom_fields/`, params, {
        headers: headers(),
      })
    },
    update: (listId, fieldId, params = {}) => {
      return $axios.post(`lists/${listId}/custom_fields/fieldId`, params, {
        headers: headers(),
      })
    },
    destroy: (listId, fieldId, params = {}) => {
      return $axios.delete(`lists/${listId}/custom_fields/fieldId`, {
        headers: headers(),
      })
    },
    companyCustomFields: () => {
      return $axios.get(`custom_fields/company_custom_fields`, {
        headers: headers(),
      })
    },
    updateCompanyCustomField: (params = {}) => {
      return $axios.put(`custom_fields/company_custom_fields`, params, {
        headers: headers(),
      })
    }
  },
  company: {
     usage: () => {
      return $axios.get(`companies/usage`, {
        headers: headers(),
      })
    }
  },
  contactFiles: {
    create: (listId, params) => {
      return $axios.post(`lists/${listId}/contact_files/`, params, {
        headers: { ...headers(), 'Content-Type': 'multipart/form-data' },
      })
    },
    import: (listId, fileId, params) => {
      return $axios.post(
        `lists/${listId}/contact_files/${fileId}/import`,
        params,
        { headers: headers() }
      )
    },
    createWithText: (listId, params) => {
      return $axios.post(`lists/${listId}/contact_files/create_with_text`, params, {
        headers: headers(),
      })
    }
  },
  campaigns: {
    index: (params = {}) => {
      return $axios.get('campaigns', { params, headers: headers() })
    },
    all: (params = {}) => {
      return $axios.get('campaigns/all', { params, headers: headers() })
    },
    create: (params) => {
      return $axios.post('campaigns', params, { headers: headers() })
    },
    show: (id) => {
      return $axios.get(`campaigns/${id}`, { headers: headers() })
    },
    update: (id, params) => {
      return $axios.put(`campaigns/${id}`, params, { headers: headers() })
    },
    delete: (id) => {
      return $axios.delete(`campaigns/${id}`, { headers: headers() })
    },
    start: (id, params) => {
      return $axios.post(`campaigns/${id}/start`, params, {
        headers: headers(),
      })
    },
    mailEvents: (id, params) => {
      return $axios.get(`campaigns/${id}/mail_events`, {
        params,
        headers: headers(),
      })
    },
    exportMailEvents: (id, params={}) => {
      return $axios.get(`campaigns/${id}/mail_events.csv`, {
        params,
        headers: headers(),
      })
    },
    duplicate: (id) => {
      return $axios.put(`campaigns/${id}/duplicate`, {}, { headers: headers() })
    },
    unschedule: (id) => {
      return $axios.put(`campaigns/${id}/unschedule`, {}, { headers: headers() })
    },
    rerun: (id) => {
      return $axios.post(`campaigns/${id}/rerun`, {}, { headers: headers() })
    },
    resume: (id) => {
      return $axios.put(`campaigns/${id}/resume`, {}, { headers: headers() })
    },
    pause: (id) => {
      return $axios.put(`campaigns/${id}/pause`, {}, { headers: headers() })
    },
    saveAsTemplate: (id) => {
      return $axios.put(`campaigns/${id}/save_as_template`, {}, {
        headers: headers(),
      })
    },
    customFields: (id) => {
      return $axios.get(`campaigns/${id}/custom_fields`, { headers: headers() })
    }
  },
  templates: {
    index: (params = {}) => {
      return $axios.get('email_templates', { params, headers: headers() })
    },
    public: (params = {}) => {
      return $axios.get('email_templates/public', {
        params,
        headers: headers(),
      })
    },
    create: (params) => {
      return $axios.post('email_templates', params, { headers: headers() })
    },
    show: (id) => {
      return $axios.get(`email_templates/${id}`, { headers: headers() })
    },
    update: (id, params) => {
      return $axios.put(`email_templates/${id}`, params, { headers: headers() })
    },
    delete: (id) => {
      return $axios.delete(`email_templates/${id}`, { headers: headers() })
    },
    test: (params) => {
      return $axios.post('email_templates/test', params, { headers: headers() })
    },
  },
  plans: {
    index: (params = {}) => {
      return $axios.get('plans', { params, headers: headers() })
    },
  },
  paymentOrders: {
    create: (params) => {
      return $axios.post('payment_orders', params, { headers: headers() })
    },
    createEmailOrder: (params) => {
      return $axios.post('payment_orders/create_email_order', params, { headers: headers() })
    },
    verify: (id, params) => {
      return $axios.post(`payment_orders/${id}/verify`, params, { headers: headers() })
    }
  },
  addresses: {
    index: (params = {}) => {
      return $axios.get('addresses', { params, headers: headers() })
    },
    show: (id) => {
      return $axios.get('addresses/${id}', { headers: headers() })
    },
    companyAddress: () => {
      return $axios.get('addresses/company_address', { headers: headers() })
    },
    create: (params) => {
      return $axios.post('addresses', params, { headers: headers() })
    },
    update: (id, params) => {
      return $axios.put(`addresses/${id}`, params, { headers: headers() })
    },
  },
  countries: {
    index: (params = {}) => {
      return $axios.get('countries', { params, headers: headers() })
    },
  },
  companies: {
    checkout: (params = {}) => {
      return $axios.get(`companies/checkout`, { params, headers: headers() })
    },
    selectPlan: (params) => {
      return $axios.put(`companies/select_plan`, params, { headers: headers() })
    },
    details: () => {
      return $axios.get(`companies/details`, { headers: headers() })
    },
    users: () => {
      return $axios.get(`companies/users`, { headers: headers() })
    },
    setupWaba: (params = {}) => {
      return $axios.post(`companies/setup_waba`, params, { headers: headers() })
    },
    activateWaPlan: (params = {}) => {
      return $axios.post(`companies/activate_wa_subscription`, params, { headers: headers() })
    }
  },
  sentEmails: {
    unsubscribe: (params) => {
      return $axios.put(
        `sent_emails/${params.sentEmailId}/unsubscribe`,
        { reason: params.reason },
        {
          headers: headers(),
        }
      )
    },
    get: (sentEmailId) => {
      return $axios.get(`sent_emails/${sentEmailId}`, { headers: headers() })
    },
  },
  segments: {
    index: (listId, params = {}) => {
      console.log(params)
      return $axios.get(`lists/${listId}/segments`, { params, headers: headers() })
    },
    create: (listId, params) => {
      return $axios.post(`lists/${listId}/segments`, params, {
        headers: headers(),
      })
    },
    show: (listId, segmentId) => {
      return $axios.get(`lists/${listId}/segments/${segmentId}`, {
        headers: headers(),
      })
    },
    update: (listId, segmentId, params) => {
      return $axios.put(`lists/${listId}/segments/${segmentId}`, params, {
        headers: headers(),
      })
    },
    delete: (listId, segmentId) => {
      return $axios.delete(`lists/${listId}/segments/${segmentId}`, {
        headers: headers(),
      })
    },
    fields: (listId) => {
      return $axios.get(`lists/${listId}/segments/fields`, {
        headers: headers(),
      })
    }
  },
  automations: {
    index: (params = {}) => {
      return $axios.get('automations', { params, headers: headers() })
    },
    create: (params) => {
      return $axios.post('automations', params, { headers: headers() })
    },
    show: (id) => {
      return $axios.get(`automations/${id}`, { headers: headers() })
    },
    update: (id, params) => {
      return $axios.put(`automations/${id}`, params, { headers: headers() })
    },
    delete: (id) => {
      return $axios.delete(`automations/${id}`, { headers: headers() })
    },
    activate: (id) => {
      return $axios.put(`automations/${id}/activate`, {}, { headers: headers() })
    },
    pause: (id) => {
      return $axios.put(`automations/${id}/pause`, {}, { headers: headers() })
    },
    createAutomationTrigger: (id, params) => {
      return $axios.post(`automations/${id}/create_trigger`, params, {
        headers: headers(),
      })
    },
    updateAutomationTrigger: (id, params) => {
      return $axios.put(
        `automations/${id}/update_trigger`,
        params,
        { headers: headers() }
      )
    },
    createAutomationStep: (id, params) => {
      return $axios.post(`automations/${id}/automation_steps`, params, {
        headers: headers(),
      })
    },
    updateAutomationStep: (id, stepId, params) => {
      return $axios.put(`automations/${id}/automation_steps/${stepId}`, params, {
        headers: headers(),
      })
    },
    deleteAutomationStep: (id, stepId) => {
      return $axios.delete(`automations/${id}/automation_steps/${stepId}`, {
        headers: headers(),
      })
    },
    report: (id, params = {}) => {

      return $axios.get(`automations/${id}/report`, { params, headers: headers() })
    },
    duplicate: (id, params) => {
      return $axios.post(`automations/${id}/duplicate`, params, { headers: headers() })
    }
  },
  mailEvents: {
    index: (params = {}) => {
      return $axios.get('mail_events', { params, headers: headers() })
    },
    download: (params = {}) => {
      return $axios.get('mail_events.csv', { params, headers: headers() })
    },
    best: (params = {}) => {
      return $axios.get('mail_events/best', { params, headers: headers() })
    },
    report: (params = {}) => {
      return $axios.get('mail_events/report', { params, headers: headers() })
    }
  },
  subscribers: {
    add: (params = {}) => {
      return $axios.post('https://api.campaignhq.co/api/v1/lists/82f9f780-cb7f-4d9f-8566-aeafadab84e1/contacts', params, {
        headers: chqApiHeader(),
      })
    },
    contactSales: (params = {}) => {
      return $axios.post('https://api.campaignhq.co/api/v1/lists/c6283ff2-5ba1-4448-bf70-6106ed8d317e/contacts', params, {
        headers: chqApiHeader(),
      })
    }
  },
  promptTemplates: {
    index: (params = {}) => {
      return $axios.get('prompt_templates', { params, headers: headers() })
    },
    show: (id) => {
      return $axios.get(`prompt_templates/${id}`, { headers: headers() })
    }
  },
  prompts: {
    create: (params = {}) => {
      return $axios.post('prompts', params, { headers: headers() })
    },
    update: (id, params = {}) => {
      return $axios.put(`prompts/${id}`, params, { headers: headers() })
    }
  },
  reports: {
    campaigns: (params = {}) => {
      return $axios.get('reports/campaigns', { params, headers: headers() })
    },
    waCampaigns: (params = {}) => {
      return $axios.get('reports/wa_campaigns', { params, headers: headers() })
    },
    downloadCampaigns: (params = {}) => {
      return $axios.get('reports/campaigns.csv', { params, headers: headers() })
    },
    automations: (params = {}) => {
      return $axios.get('reports/automations', { params, headers: headers() })
    }
  },
  whatsApp: {
    templates: (params = {}) => {
      return $axios.get('whatsapp_templates', { params, headers: headers() })
    },
    createTemplate: (params = {}) => {
      return $axios.post('whatsapp_templates', params, { headers: headers() })
    },
    updateTemplate: (id, params = {}) => {
      return $axios.put(`whatsapp_templates/${id}`, params, { headers: headers() })
    },
    show: (id) => {
      return $axios.get(`whatsapp_templates/${id}`, { headers: headers() })
    },
    delete: (id) => {
      return $axios.delete(`whatsapp_templates/${id}`, { headers: headers() })
    },
    campaigns: {
      create: (params = {}) => {
        return $axios.post('whatsapp_campaigns', params, { headers: headers() })
      },
      index: (params = {}) => {
        return $axios.get('whatsapp_campaigns', { params, headers: headers() })
      },
      show: (id) => {
        return $axios.get(`whatsapp_campaigns/${id}`, { headers: headers() })
      },
      update: (id, params = {}) => {
        return $axios.put(`whatsapp_campaigns/${id}`, params, { headers: headers() })
      },
      start: (id, params) => {
        return $axios.post(`whatsapp_campaigns/${id}/start`, params, {
          headers: headers(),
        })
      },
      mailEvents: (id, params) => {
        return $axios.get(`whatsapp_campaigns/${id}/mail_events`, {
          params,
          headers: headers(),
        })
      },
    },
    conversations: {
      index: (params = {}) => {
        return $axios.get('conversations', { params, headers: headers() })
      },
      update: (id, params = {}) => {
        return $axios.put(`conversations/${id}`, params, { headers: headers() })
      },
      messages: (id, params = {}) => {
        return $axios.get(`conversations/${id}/messages`, { params, headers: headers() })
      },
      sendMessage: (id, params = {}) => {
        return $axios.post(`conversations/${id}/send_message`, params, { headers: headers() })
      },
      show: (id) => {
        return $axios.get(`conversations/${id}`, { headers: headers() })
      },
      media: (id, mediaId) => {
        return  $axios.get(`conversations/${id}/media/${mediaId}`, { headers: headers() })
      }
    },
  },
}

const chqApiHeader = () => {
  let h = {}

  h['Content-Type'] = 'application/json'
  h['Authorization'] = 'Bearer chq_usr_LMTcQvciMa6uFaHofJzvdNVKGg7FSm'
  return h
}

const headers = (context) => {
  let h = {}
  h['access-token'] = getCookie('access-token')
  h['token-type'] = getCookie('token-type')
  h['uid'] = getCookie('uid')
  h['expiry'] = getCookie('expiry')
  h['client'] = getCookie('client')
  return h
}
